import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        ©2023 Uato StarLink Tracker. All Rights Reserved. Website Made by
        DerickDu
      </footer>
    );
  }
}

export default Footer;
